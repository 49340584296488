// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-draft-tsx": () => import("./../src/pages/draft.tsx" /* webpackChunkName: "component---src-pages-draft-tsx" */),
  "component---src-pages-friend-tsx": () => import("./../src/pages/friend.tsx" /* webpackChunkName: "component---src-pages-friend-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-project-tsx": () => import("./../src/pages/project.tsx" /* webpackChunkName: "component---src-pages-project-tsx" */),
  "component---src-pages-toolbox-browser-keyboard-tsx": () => import("./../src/pages/toolbox/browser-keyboard.tsx" /* webpackChunkName: "component---src-pages-toolbox-browser-keyboard-tsx" */),
  "component---src-pages-toolbox-browser-ua-tsx": () => import("./../src/pages/toolbox/browser-ua.tsx" /* webpackChunkName: "component---src-pages-toolbox-browser-ua-tsx" */),
  "component---src-pages-toolbox-browser-viewport-tsx": () => import("./../src/pages/toolbox/browser-viewport.tsx" /* webpackChunkName: "component---src-pages-toolbox-browser-viewport-tsx" */),
  "component---src-pages-toolbox-device-data-tsx": () => import("./../src/pages/toolbox/device-data.tsx" /* webpackChunkName: "component---src-pages-toolbox-device-data-tsx" */),
  "component---src-pages-toolbox-html-5-canvas-tsx": () => import("./../src/pages/toolbox/html5-canvas.tsx" /* webpackChunkName: "component---src-pages-toolbox-html-5-canvas-tsx" */),
  "component---src-pages-toolbox-html-5-connection-tsx": () => import("./../src/pages/toolbox/html5-connection.tsx" /* webpackChunkName: "component---src-pages-toolbox-html-5-connection-tsx" */),
  "component---src-pages-toolbox-html-5-geolocation-tsx": () => import("./../src/pages/toolbox/html5-geolocation.tsx" /* webpackChunkName: "component---src-pages-toolbox-html-5-geolocation-tsx" */),
  "component---src-pages-toolbox-html-5-input-tsx": () => import("./../src/pages/toolbox/html5-input.tsx" /* webpackChunkName: "component---src-pages-toolbox-html-5-input-tsx" */),
  "component---src-pages-toolbox-html-5-online-tsx": () => import("./../src/pages/toolbox/html5-online.tsx" /* webpackChunkName: "component---src-pages-toolbox-html-5-online-tsx" */),
  "component---src-pages-toolbox-tsx": () => import("./../src/pages/toolbox.tsx" /* webpackChunkName: "component---src-pages-toolbox-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-project-post-tsx": () => import("./../src/templates/project-post.tsx" /* webpackChunkName: "component---src-templates-project-post-tsx" */)
}

